<template>
	<ValidationProvider :name="$attrs.name" :rules="rules" v-slot="{ errors }">
		<div class="text-h4 my-3" v-html="question.options.label[lang]"></div>
		<div class="mb-6 mb-sm-8" v-if="_.includes(question.short_name.toLowerCase(), 'nps')" id="nps-question">
			<v-col cols="12" class="px-0 pt-1">
				<v-btn-toggle :error-messages="errors"
				              v-model="inputValue" @change="setValue('nps')"
				              :color="AppStore.data.general.primary_color.hexa">
					<v-btn v-for="(item, index) in question.options.items[lang]"
					       :class="item.value === inputValue ? 'v-btn--active' : ''" :key="index" class="px-0">
						{{ item.value }}
					</v-btn>
				</v-btn-toggle>
			</v-col>

			<v-col cols="12" class="pa-0" v-if="inputValue !== null && inputValue !== undefined">
				<p v-html="question.options.items[lang][inputValue].label"></p>
			</v-col>

			<!--            <v-col cols="6" class="py-0 nps-label">-->
			<!--                {{ question.options.items[lang][0].label }}-->

			<!--            </v-col>-->
			<!--            <v-col cols="6" class="text-right py-0 nps-label">-->
			<!--                {{ question.options.items[lang][10].label }}-->
			<!--            </v-col>-->
			<!--	        <v-col cols="12" class="d-flex justify-center pt-1" id="nps" >-->
			<!--                <v-radio-group-->
			<!--                        v-model="inputValue"-->
			<!--                        :column="$vuetify.breakpoint.smAndUp ? question.options.column : true"-->
			<!--                        :row="$vuetify.breakpoint.smAndUp ? question.options.row : false"-->
			<!--                        :dense="!$vuetify.breakpoint.smAndUp"-->
			<!--                        @change="setValue()"-->
			<!--                >-->
			<!--                    <v-radio v-for="(item, index) in question.options.items[lang]"-->
			<!--                             :key="index"-->
			<!--                             :color="color.hexa"-->
			<!--                             :value="item.value"-->
			<!--                    >-->
			<!--                        <template v-slot:label>-->
			<!--                            <span v-if="index !== 0 && index !== 10" v-html="item.label"></span>-->
			<!--                        </template>-->
			<!--                    </v-radio>-->
			<!--                </v-radio-group>-->
			<!--	        </v-col>-->
		</div>

		<v-radio-group v-else
		               :error-messages="errors"
		               v-model="inputValue"
		               :column="question.options.column"
		               :row="question.options.row"
		               :dense="!$vuetify.breakpoint.smAndUp"
		               @change="setValue('radio group')"
		               class="mb-6 mb-sm-8"
		               hide-details
		>
			<v-radio v-for="(item, index) in question.options.items[lang]"
			         :key="index"
			         :color="color.hexa"
			         :value="item.value"
			>
				<template v-slot:label>
					<span v-html="item.label"></span>
				</template>
			</v-radio>
		</v-radio-group>
	</ValidationProvider>

</template>

<script>
import {ValidationProvider} from "vee-validate";
import AppStore from "../../../stores/AppStore";
import _ from "lodash";
import {EventBus} from "../../../services/Request";

export default {
    name: 'radio-viewer',

	components: {
		ValidationProvider
	},

	props: {
		value: {
			type: [Object],
			default() {
				return {}
			}
		},
		lang: {
			type: [String],
			default: 'fr'
		},
		color: {
			type: [String, Object],
			default: 'primary'
		},
		rules: {
			type: [Object, String],
			default: ""
		},
		questionIndex: {
			type: [Number],
			default: 0
		},
	},

	data: () => ({
		inputValue: null,
		AppStore
	}),

    mounted() {

    },

	created() {
		if (!_.isEmpty(AppStore.rating.questions_answers) && AppStore.rating.questions_answers[this.question.id]) {
			this.inputValue = AppStore.rating.questions_answers[this.question.id].content
		}
	},

    computed: {
        question: {
            get() {
                return this.value;
            },

            set(newValue) {
                this.$emit('input', newValue)
            }
        },
        _() {
            return _;
        },
    },

    watch: {},

    methods: {
        setValue() {
            if (_.includes(this.question.short_name.toLowerCase(), 'nps') && (this.inputValue === undefined || this.inputValue === null)) {
                delete AppStore.rating.questions_answers[this.question.id]
            } else {
                this.$set(AppStore.rating.questions_answers, this.question.id, {
                    question_id: this.question.id,
                    content: this.inputValue
                })
            }
            // if (AppStore.app_has_conditioned_question) {
            //     console.log('APP HAS CONDITIONED QUESTIONS')
            //     console.log(this.question.id)
            //     console.log(AppStore.questionsWithConditionsIds)
            //     if (AppStore.questionsWithConditionsIds.some(v => v.includes(this.question.id))) {
            //         console.log('ID INCLUDE SO UPDATE QUESTION')
            //
            //         EventBus.$emit('update-question', {
            //             id: this.questionIndex,
            //             value: this.inputValue,
            //         })
            //     }
            // }


            const conditionedQuestion = _.find(AppStore.conditionedQuestion, (q) => {
                return q.conditional_question_id.id === this.question.id
            });

            // console.log(conditionedQuestion)
            if (!_.isEmpty(conditionedQuestion)) {
                // console.log("CONDITIONED QUESTION")
                if (conditionedQuestion.conditional_answer == this.inputValue) {
                    // console.log('set')
                    // console.log('qi'+conditionedQuestion.id + 'remove d-none')
                    // console.log(this.inputValue)
                    // console.log(conditionedQuestion.conditional_answer)
                    EventBus.$emit('update-question', {
                        id: conditionedQuestion.id,
                        show: true,
                        from: 'if radio'
                    })
                } else {
                    // console.log('qi'+conditionedQuestion.id + ' d-none')
                    // console.log(this.inputValue)
                    // console.log(conditionedQuestion.conditional_answer)
                    EventBus.$emit('update-question', {
                        id: conditionedQuestion.id,
                        show: false,
                        from: 'else radio'
                    })
                }
            }

            // console.log(AppStore.rating)
        }

    }
};
</script>
<style>
@media screen and (max-width: 600px) {
    .v-btn-toggle .v-btn.v-btn.v-size--default {
        min-width: 27px;
        min-height: 0;
    }
}
</style>


