<template>
	<ValidationProvider :name="$attrs.name" :rules="rules" v-slot="{ errors }">

		<!--		<v-slider-->
		<!--			v-model="inputValue"-->
		<!--			:label="question.options.label[lang]"-->
		<!--			:hint="question.options.hint[lang][inputValue].label"-->
		<!--			persistent-hint-->
		<!--			@change="setValue()"-->
		<!--			:min="question.options.min"-->
		<!--			:max="question.options.max"-->
		<!--			step="1"-->
		<!--			tick-size="5"-->
		<!--			ticks="always"-->
		<!--		></v-slider>-->
		<!--		<pre>{{question}}</pre>-->
		<v-container fluid>
			<v-row>
				<v-col class="px-0 py-0 flex-grow-1 flex-shrink-0">
					<div class="text-h4 my-3" v-html="question.options.label[lang]"></div>
				</v-col>

				<v-col cols="12" class="pa-0 mb-3 mb-sm-6" id="appreciation-rating">
					<v-rating class="d-inline-block" v-model="inputValue" :error-messages="errors"
					          @input="setValue()" :length="question.options.max">
						<template v-slot:item="props">
							<v-icon :style="$vuetify.breakpoint.smAndUp ? 'font-size: 50px;' : 'font-size: 35px;'"
							        :color="getRateColor(props.isFilled)"
							        small
							        class="pa-0"
							        style="letter-spacing: -3px;"
							        @click="props.click">
								{{ props.isFilled ? 'mdi-star' : 'mdi-star-outline' }}
							</v-icon>
						</template>
					</v-rating>
					<div class="ml-0 ml-sm-3 mt-2 text-right d-inline-block float-right" style="margin-top:5px">
						<v-chip class="slider-chips" :small="!$vuetify.breakpoint.smAndUp" outlined>
							{{ question.options.hint[lang][inputValue].label }}
						</v-chip>
						<v-icon style="margin-left: 7px;"
						        @click.native="click(question)"
						        v-if="inputValue > 0" :size="$vuetify.breakpoint.smAndUp ? 30 : 15">
							mdi mdi-close
						</v-icon>
					</div>
				</v-col>
			</v-row>
		</v-container>
	</ValidationProvider>

</template>

<script>
import {ValidationProvider} from "vee-validate";
import AppStore from "../../../stores/AppStore";
import _ from "lodash";
import {EventBus} from "../../../services/Request";
import Vue from 'vue';

export default {
	name: 'slider-viewer',

	components: {
		ValidationProvider
	},

	props: {
		value: {
			type: [Object],
			default() {
				return {}
			}
		},
		lang: {
			type: [String],
			default: 'fr'
		},
		questionIndex: {
			type: [Number],
			default: 0
		},
		rules: {
			type: [Object, String],
			default: ""
		},
	},

	data: () => ({
		AppStore,
		inputValue: 0,
		Vue,
	}),

	mounted() {
	},

	created() {
		if (!_.isEmpty(AppStore.rating.questions_answers) && AppStore.rating.questions_answers[this.question.id]) {
			this.inputValue = parseInt(AppStore.rating.questions_answers[this.question.id].content)
		}
	},

	computed: {
		question: {
			get() {
				return this.value;
			},

			set(newValue) {
				this.$emit('input', newValue)
			}
		},
		_() {
			return _;
		}
	},

	watch: {},

	methods: {
		setValue() {
			// console.info('setValue from slider')
			this.$set(AppStore.rating.questions_answers, this.question.id, {
				question_id: this.question.id,
				content: this.inputValue
			})
			const conditionedQuestion = _.find(AppStore.conditionedQuestion, (q) => {
				return q.conditional_question_id.id === this.question.id
			});

			// console.log(conditionedQuestion)
			if (!_.isEmpty(conditionedQuestion)) {
				// console.log("CONDITIONED QUESTION")
				if (conditionedQuestion.conditional_answer == this.question.options.hint[this.lang][this.inputValue].label) {
					// console.log('set')
					// console.log('qi'+conditionedQuestion.id + 'remove d-none')
					EventBus.$emit('update-question', {
						id: conditionedQuestion.id,
						show: true
					})
				} else {
					// console.log('qi'+conditionedQuestion.id + ' d-none')
					EventBus.$emit('update-question', {
						id: conditionedQuestion.id,
						show: false
					})
				}
			}

			// console.log(AppStore.rating)
		},

		click(question){
			this.inputValue = 0; Vue.delete(AppStore.rating.questions_answers, question.id)
		},

        getRateColor(isFilled) {
            let color = 'orange'
            if(isFilled){
                if(!AppStore.data.configuration.override_appreciation_questions_stars_color && AppStore.data.configuration.default_appreciation_questions_stars_color.hexa){
                    color = AppStore.data.configuration.default_appreciation_questions_stars_color.hexa
                } else {
                    color = AppStore.data.configuration['override_appreciation_questions_stars_color_star_'+this.inputValue].hexa
                }
            } else {
                color = 'grey lighten-0'
            }

            return color
        }
	}
};
</script>
