<template>
	<ValidationProvider :name="$attrs.name" :rules="rules" v-slot="{ errors }">

		<div class="text-h4 my-3" v-html="question.options.label[lang]"></div>

		<v-select v-model="inputValue"
		          :error-messages="errors"
		          class="pt-1 mb-6 mb-sm-8"
		          :items="question.options.items[lang]"
		          item-text="label"
		          item-value="value"
		          :multiple="question.options.multiple"
		          :chips="question.options.chips"
		          :return-object="question.options.returnObject"
		          :deletable-chips="question.options.deletableChips"
		          :hint="question.options.hint ? question.options.hint[lang] : ''"
		          :placeholder="$t('component.select')"
		          :persistent-hint="question.options.persistentHint"
		          :outlined="question.options.outlined"
		          :solo="question.options.solo"
		          :clear-icon="question.options.clearIcon"
		          :clearable="question.options.clearable"
		          :hide-details="!question.options.persistentHint"
		          flat
		          :menu-props="{ maxHeight: 600 }"
		          @change="setValue()"
		>
			<template v-slot:item='{item}'> <div v-html='item.label'/> </template>
			<template v-slot:selection='{item}'> <div v-html='item.label'/> </template>
		</v-select>
	</ValidationProvider>
</template>

<script>
import {ValidationProvider} from "vee-validate";
import AppStore from "../../../stores/AppStore";
import _ from "lodash";
import {EventBus} from "../../../services/Request";

export default {
	name: 'select-viewer',

	components: {
		ValidationProvider
	},

	props: {
		value: {
			type: [Object],
			default() {
				return {}
			}
		},
		questionIndex: {
			type: [Number],
			default: 0
		},
		lang: {
			type: [String],
			default: 'fr'
		},
		rules: {
			type: [Object, String],
			default: ""
		},
	},

	data: () => ({
		inputValue : ""
	}),

	mounted() {

	},

	created() {
        if(!_.isEmpty(AppStore.rating.questions_answers) && AppStore.rating.questions_answers[this.question.id]){
            this.inputValue = AppStore.rating.questions_answers[this.question.id].content
        }
	},

	computed: {
		question: {
			get() {
				return this.value;
			},

			set(newValue) {
				this.$emit('input', newValue)
			}
		},
	},

	watch: {},

	methods: {
		setValue() {
			// console.info('setValue from select')
			this.$set(AppStore.rating.questions_answers, this.question.id, {
				question_id: this.question.id,
				content: this.inputValue
			})
			const conditionedQuestion = _.find(AppStore.conditionedQuestion, (q) => {
				return q.conditional_question_id.id === this.question.id
			});

			// console.log(conditionedQuestion)
			if (!_.isEmpty(conditionedQuestion)) {
				// console.log("CONDITIONED QUESTION")
				if (conditionedQuestion.conditional_answer == this.inputValue) {
					// console.log('set')
					// console.log('qi'+conditionedQuestion.id + 'remove d-none')
					EventBus.$emit('update-question', {
						id: conditionedQuestion.id,
						show: true
					})
				} else {
					// console.log('qi'+conditionedQuestion.id + ' d-none')
					EventBus.$emit('update-question', {
						id: conditionedQuestion.id,
						show: false
					})
				}
			}

			// console.log(AppStore.rating)
		}
	}
};
</script>
<style>
.v-select i {
	font-size: 1.8rem!important;
}
</style>